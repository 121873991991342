export const firebaseConfig = {
  apiKey: "AIzaSyAzPNGyO-fMBLP7WqPrqE2rFlHUXInasLc",
  authDomain: "mcginleyorthopedics.firebaseapp.com",
  projectId: "mcginleyorthopedics",
  storageBucket: "mcginleyorthopedics.appspot.com",
  messagingSenderId: "175345884666",
  appId: "1:175345884666:web:320572db235b4465dfd843"
};

export const vapidKey = `BMu3AID7CbkbuVRhZVZDGpLhNuVOCloYGUw9NVQ-yW3CN2S7I6z96Agwv5ulcXEiQDkt6oOrX9mjoqi5E4JIPwE`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}